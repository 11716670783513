<template>
    <div>
        <!-- About Us -->
        <section class="privacy-policy style-two">
            <div class="auto-container">
                <div class="row mt-5">
                    <h2>تاريخ السريان: (مارس 2021)</h2>
                    <!-- Info Column -->
                    <div class="info-column col-12">
                        <h3><strong>1)</strong> نظرة عامة</h3>

                        <section>
                            <p>
                                <strong>1.1.</strong> تحكم هذه الشروط والسياسات
                                المكمّلة وصولك واستخدامك لتطبيق نزهة
                                على متاجر جوجل بلاي وأبل ستور (يُشار إليه بـ
                                "تطبيق نزهة")، وكافة المحتوى أو الخدمات أو
                                المعلومات التي تقدم من خلاله.
                            </p>

                            <p>
                                <strong>1.2.</strong> يرجى قراءة هذه الشروط
                                والسياسات والقواعد والإرشادات التي تتعلق
                                باستخدامك للتطبيق بعناية تامة للتأكد من فهمك
                                لكلِ بند قبل استخدام التطبيق لأنها تؤثر على
                                حقوقك القانونية؛ فمن خلال الوصول إلى أو استخدام
                                أيّ جزء من التطبيق أو الخدمات، فأنت توافق وتؤكد
                                على الالتزام بهذه الشروط، إذا كنت لا توافق على
                                بنود هذه الشروط والسياسات وأيّ تعديلات لاحقة
                                عليها، يرجى عدم الوصول إلى خدماتنا.
                            </p>

                            <p>
                                <strong>1.3.</strong> نحتفظ بالحق في تعديل أو
                                تحديث أو تغيير هذه الشروط من حين لآخر حسب رؤيتنا
                                ووفقًا لإرادتنا المنفردة، ويجب عليك الاطلاع
                                عليها بشكل منتظم لمعرفة أيّ تغييرات تطرأ عليها،
                                ويعتبر استمرارك في استخدام خدمات التطبيق موافقة
                                صريحة على الالتزام بالشروط المُعدّلة والمطبّقة
                                في وقت استخدامك للتطبيق.
                            </p>

                            <h4><strong>1.4.</strong> أنت تقر بحقنا في:</h4>

                            <p>
                                <strong>1.4.1.</strong> تعديل أو تغيير هذه
                                الشروط والسياسات الأخرى.
                            </p>

                            <p>
                                <strong>1.4.2.</strong> رفض وصولك أو أيّ مستخدم
                                إلى التطبيق.
                            </p>

                            <p>
                                <strong>1.4.3.</strong> إنهاء أو إيقاف الحسابات
                                التي تخالف الشروط والسياسات أو القوانين السارية
                                دون إشعار مسبق.
                            </p>

                            <p>
                                <strong>1.4.4.</strong> إجراء تغييرات على
                                التطبيق أو فرض رسوم إضافية على الخدمات، وسيتم
                                إخطارك بذلك.
                            </p>

                            <p>
                                <strong>1.4.5.</strong> تعديل أو إنهاء الخدمة أو
                                حجب التطبيق سواء بشكل دائم أو مؤقت.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>2)</strong> التعريفات</h3>
                        <section>
                            <h4>
                                يكون للكلمات الواردة في هذه الشروط أو السياسات
                                الأخرى المعاني المشار بجانب كل منها: 
                            </h4>

                            <p>
                                <strong>2.1.</strong> "التطبيق"، "نحن"، "ضمير
                                المتكلم"، "ضمير الملكية": تشير إلى تطبيق
                                نزهة وكافة الصفحات والأدوات والخدمات
                                المتاحة من خلاله و شركة نزهة للخدمات العقارية
                                باعتبارها مالك التطبيق.
                            </p>

                            <p>
                                <strong>2.2.</strong> "الحساب": يشير إلى حساب
                                المستخدم والذي يمكِّنه من الاستفادة بخدمات
                                التطبيق.
                            </p>

                            <p>
                                <strong>2.3.</strong> "المستخدم"، "أنت": يشير
                                إلى كل من يسجل حساب أو يستخدم التطبيق والخدمات.
                            </p>

                            <p>
                                <strong>2.4.</strong> "موفر الخدمة"، "مالك
                                العقار": يشير إلى كل كيان يسجل حساب ويقبل الشروط
                                والأحكام بغرض عرض العقارات للإيجار من خلال
                                التطبيق.
                            </p>

                            <p>
                                <strong>2.5.</strong> "العميل": يشير إلى كل من
                                يسجل حساب ويقبل الشروط والأحكام بغرض حجز عقار.
                                من خلال تطبيق نزهة.
                            </p>

                            <p>
                                <strong>2.6.</strong> "الحجز": يشير إلى خدمات
                                حجز عقار بقصد الإيجار لمدة محددة من خلال تطبيق
                                نزهة.
                            </p>

                            <p>
                                <strong>2.7.</strong> "العقار": يشير إلى الأماكن
                                التي يوفرها موفري الخدمات للإيجار (على سبيل
                                المثال الشاليهات والاستراحات والفنادق والفلل
                                والشقق أو غير ذلك).
                            </p>

                            <p>
                                <strong>2.8.</strong> "الشروط والأحكام"،
                                "الاتفاقية": تشير إلى شروط الاستخدام وسياسة
                                الخصوصية وكافة السياسات الأخرى.
                            </p>

                            <p>
                                <strong>2.9.</strong> "القوانين": تشير إلى
                                القوانين والأنظمة السارية والمعمول بها في سلطنة
                                عمان.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>3)</strong> النطاق القانوني للخدمات</h3>

                        <section>
                            <p>
                                <strong>3.1.</strong> تطبيق نزهة هو عبارة
                                عن تطبيق إلكتروني يعمل على أنظمة الأندرويد و اي
                                أو اس، ويوفر للعملاء خدمة البحث وإجراء حجوزات
                                للعقارات التي يقدمها ملاك العقارات وموفري
                                الخدمات وإتمام عملية الحجز عن طريق الإنترنت
                                مقابل عمولة؛ ويقتصر دور التطبيق على الوساطة بين
                                ملاك العقارات والعملاء وبالتالي كافة المحتويات
                                المتاحة على التطبيق هي لأغراض تقديم الخدمة.
                            </p>

                            <p>
                                <strong>3.2.</strong> تقدم جميع الخدمات وفقًا
                                للقوانين المعمول بها في سلطنة عمان وأحكام قانون
                                التجارة الإلكترونية على كافة الخدمات التي يتم
                                طلبها من خلال التطبيق.
                            </p>

                            <p>
                                <strong>3.3.</strong> تطبق شركة نزهة للخدمات
                                العقارية نفس الشروط والأحكام في حال تم تقديم هذه
                                الخدمات من خلال الموقع الإلكتروني.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>4)</strong> الطبيعة القانونية للشروط</h3>

                        <section>
                            <p>
                                <strong>4.1.</strong> تعد هذه الشروط عقداً
                                صحيحاً كاملاً الأركان القانونية نافذًا في
                                مواجهتك (سواء كنت موفر خدمة أو عميل) وأحكامه
                                والتزاماته ملزمة لهم جميعاً، ولا يجوز التحلل
                                منها أو إنهائها طالما أنتجت آثارها القانونية،
                                وبمجرد استخدامك للتطبيق فهذا يعني اطلاعك عليها،
                                وأصبحت ملزمًا بها كالتزامك بالعقود التي توقّع
                                عليها خطيًا.
                            </p>

                            <p>
                                <strong>4.2.</strong> تم صياغة هذه الشروط وفقًا
                                لقوانين سلطنة عمان، وأنت مُلزم بكافة الأحكام
                                الواردة بها ولا يجوز لك استبعاد أيّ من أحكامها،
                                فإذا كانت أحكام هذه الشروط تتعارض مع الأحكام
                                القانونية المُلزمة في البلد الذي تحمل جنسيته أو
                                الذي تقيم فيه أو الذي تستخدم التطبيق على أرضه؛
                                فيجب عليك عدم استخدام التطبيق أو أيّ من خدماته،
                                وبالتالي عدم الدخول معنا في هذه الاتفاقية؛ ونحن
                                غير ملزمين بتلبية أيّ أحكام قانونية معمول بها في
                                بلد أخر.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>5)</strong> الوصول إلى الخدمات</h3>

                        <p>
                            <strong>5.1.</strong> يتحمل المستخدم مسؤولية الحصول
                            على جميع الأجهزة الخاصة والبرمجيات والأدوات الأخرى
                            اللازمة للوصول إلى واستخدام تطبيق نزهة
                            وجميع النفقات المتعلقة بذلك بما في ذلك رسوم الاتصال
                            بالإنترنت؛ حيث تتطلب وظائف معينة من التطبيق أن يكون
                            لديك اتصال نشط بالإنترنت سواء عن طريق Wi-Fi، أو من
                            قِبل مزود البيانات، وبأيّ حال من الأحوال لا يتحمّل
                            تطبيق نزهة المسؤولية عن عدم عمل التطبيق
                            بكامل وظائفه إذا لم تكن مصلت بالإنترنت أو عدم توافق
                            التطبيق مع نظام التشغيل الخاص بجهازك.
                        </p>

                        <p>
                            <strong>5.2.</strong> أنت توافق على الامتثال
                            والالتزام بجميع الإرشادات والإشعارات وقواعد وسياسات
                            التشغيل والتعليمات المتعلقة باستخدام الخدمات
                            والتطبيق، وتؤكد باطلاعك على هذه الوثائق وقبولك لكل
                            ما ورد فيها قبل دخولك معنا في هذه العلاقة العقدية.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>6)</strong> الحساب وضوابط الاستخدام</h3>

                        <section>
                            <h4>
                                <strong>6.1.</strong> للاستفادة من معظم جوانب
                                الخدمات التي يقدمها التطبيق، يتعيّن عليك تسجيل
                                حساب شخصي فعَّال ("الحساب")، وتوافق على:
                            </h4>

                            <p>
                                <strong>6.1.1</strong> الإقرار بأنك تتمتع
                                بالأهلية القانونية الكاملة غير المقيدة للدخول
                                معنا في هذه الاتفاقية (على سبيل المثال: بلوغ
                                السن القانوني) وأنك غير مصاب بأحد عوارض الأهلية.
                            </p>
                            <p>
                                <strong>6.1.2</strong> الامتثال لجميع القوانين
                                واللوائح المعمول بها في سلطنة عمان المتعلقة
                                باستخدامك للتطبيق.
                            </p>
                            <p>
                                <strong>6.1.3</strong> تسجيل حساب صحيح،
                                والموافقة على استخدام التطبيق وفقاً للشروط
                                والأحكام السارية وأيّ سياسات أو إرشادات يُقدمها
                                التطبيق والتعديلات اللاحقة عليها.
                            </p>
                            <p>
                                <strong>6.1.4</strong> الامتثال لأي إشعارات يتم
                                إرسالها من خلال التطبيق فيما يتعلق باستخدامك
                                للخدمات.
                            </p>
                            <p>
                                <strong>6.1.5</strong> تزويدنا ببيانات شخصيّة
                                كاملة وصحيحة ودقيقة وحديثة عند تسجيل الحساب،
                                والالتزام بتحديثها كلما طرأ عليها أيّ تغيرات،
                                وتحمل مسؤولية أيّ أخطاء فيها.
                            </p>
                            <p>
                                <strong>6.1.6</strong> الالتزام بالحفاظ على أمان
                                وسرية المعلومات المتعلقة بالحساب وبالأخص كلمة
                                المرور، وعدم الإفصاح عنها وتقييد الغير من الوصول
                                إليها.
                            </p>
                            <p>
                                <strong>6.1.7</strong> تحمُل المسؤولية عن جميع
                                الأنشطة التي تحدث أو تصدر من حسابك.
                            </p>
                            <p>
                                <strong>6.1.8</strong> الموافقة على عدم استخدام
                                الحساب لأغراض غير قانونية أو احتيالية أو غير
                                لائقة أو مسيئة لنا أو للغير أو لعلامتنا التجارية
                                أو لخدماتنا المتاحة عبر التطبيق، وتوافق على عدم
                                الانخراط أو مساعدة الغير في أيّ أنشطة تعرقل سير
                                وتشغيل التطبيق أو الخدمات.
                            </p>
                            <p>
                                <strong>6.1.9</strong> إخطارنا على الفور بأيّ
                                استخدام غير قانوني أو غير مصرّح به أو في حال
                                تعرض الحساب للاختراق.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>6.2.</strong> يحق لنا رفض أو إلغاء تسجيل
                                أي مستخدم، أو حظر أيّ مستخدم من استخدام التطبيق،
                                أو إنهاء الحساب ورفض أيّ/وجميع الاستخدامات
                                الحالية أو المستقبلية وفي أيّ وقت في الحالات
                                التالية:
                            </h4>
                            <p>
                                <strong>6.2.1</strong> إذا ثبُت أنّك قدمت
                                معلومات غير صحيحة أو غير دقيقة أو غير حديثة أو
                                غير كاملة.
                            </p>
                            <p>
                                <strong>6.2.2</strong> إذا ثبُت استخدامك للحساب
                                بطريقة تخالف الشروط والسياسات المتّبعة بالتطبيق.
                            </p>
                            <p>
                                <strong>6.2.3</strong> إذا ثبُت أن تقوم بإجراء
                                حجوزات وهمية أو تستخدم وسائل دفع ليست ملكًا لك
                                أو بست مفوضًا باستخدامها.
                            </p>
                            <p>
                                <strong>6.2.4</strong> إذا ثبُت أن تصرفاتك تسببت
                                في أيّ أضرار أو خسائر أو مسؤولية تجاهنا أو تجاه
                                أي مستخدم آخر.
                            </p>
                            <p>
                                <strong>6.2.5</strong> إذا رأينا أي انتهاكات
                                أخرى في أيّ ظروف أخرى وفقًا لما نراه مناسبًا.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>7)</strong> سياسة الحجز</h3>
                        <p>
                            <strong>7.1.</strong> تنطبق هذه السياسة على أيّ حجز
                            يتم من خلال تطبيق نزهة الذي يعرض خدمات
                            حجز العقارات المعلنة من موفريها أو ملاكها والتي لا
                            نملكها أو نمارس أيّ حق من الحقوق التصرف عليها وإكمال
                            إجراءات الحجز من خلال التطبيق.
                        </p>
                        <p>
                            <strong>7.2.</strong> تقتصر الحجوزات في تطبيق
                            نزهة حصرياً على الأشخاص الذين يمكنهم
                            إبرام عقود عبر الإنترنت بشكل قانوني وفقاً لقوانين
                            سلطنة عمان.
                        </p>
                        <p>
                            <strong>7.3.</strong> عند إجراء حجز، يجب عليك اتباع
                            التعليمات حول كيفية إجراء الحجز وإجراء التغييرات
                            عليه قبل إرسال وتأكيد الحجز.
                        </p>
                        <p>
                            <strong>7.4.</strong> كافة بيانات العقارات بما فيها
                            الأسعار والصور وتوفر الحجز وتفاصيل الخدمات الأخرى هي
                            بيانات يتم توفيرها مباشرة من موفري تلك الخدمات؛ وعلى
                            الرغم من أننا نولي اهتماماً كبيراً لضمان صحة هذه
                            التفاصيل ومدى مطابقتها للواقع إلا أن مدى مسؤوليتنا
                            محكوم بنطاق خدماتنا المباشرة ويعتبر ضمان مطابقة
                            البيانات والأسعار والتوفر مسؤولية مباشرة لموفر
                            الخدمة في التطبيق.
                        </p>
                        <p>
                            <strong>7.5.</strong> يتم عرض جميع الأسعار شاملة
                            الضرائب بالعملة المحلية (الريال العماني).
                        </p>
                        <p>
                            <strong>7.6.</strong> يجب على العميل إتمام الدفع
                            مقابل عملية الحجز بإحدى الطرق التي نقبلها في التطبيق
                            بمجرد اختيار العقار وتأكيد بياناتك، كما يلتزم بدفع
                            مبلغ تأمين للعقار الذي يحجزه.
                        </p>
                        <p>
                            <strong>7.7.</strong> يجب أن يكون للعميل الحق الكامل
                            في استخدام طريقة الدفع المستخدمة في عمليات الحجز،
                            وأن تحتوي طريقة الدفع على أموال كافية أو ائتمان أو
                            تسهيلات دفع أخرى لتغطية عملية الحجز.
                        </p>
                        <p>
                            <strong>7.8.</strong> بإتمام عملية الحجز، فأنت
                            تفوضنا صراحةً قبل قبول حجزك، بالتحقق من عمليات
                            الائتمان والأمان، بما في ذلك على سبيل المثال لا
                            الحصر، التحقق من صحة بطاقتك الائتمانية أو الوسيلة
                            التي استخدمتها في الدفع.
                        </p>
                        <p>
                            <strong>7.9.</strong> يتم تأكيد الحجز وإصدار رقم
                            تأكيد الحجز مباشرة بعد إتمام إجراءات التأكيد والدفع
                            بالوسائل المتاحة.
                        </p>
                        <p>
                            <strong>7.10.</strong> يتم تحديد تاريخ الوصول وتاريخ
                            المغادرة من خلال تقويم التطبيق.
                        </p>
                        <p>
                            <strong>7.11.</strong> عند تأكيد الحجز يتم خصم
                            تكاليف الحجز حسب شروط موفر الخدمة وإصدار رقم تأكيد
                            الحجز مباشرة.
                        </p>
                        <p>
                            <strong>7.12.</strong> في حال عدم توافر بعض المرافق
                            التي تم حجزها بناءً على طلبك لدى وصولك إلى العقار،
                            ننصحك بتقديم شكوى إلى مالك العقار أو المدير المسؤول،
                            نحن لا نضمن لك توافر أيّ من المرافق التي قمت بطلبها
                            مسبقًا.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>8)</strong> التعهدات والضمانات</h3>
                        <section>
                            <h4>
                                <strong>8.1.</strong> موفري الخدمات (ملاك
                                العقارات)
                            </h4>
                            <section>
                                <h5>
                                    <strong>8.1.1</strong> يتعهد موفر الخدمة
                                    بالآتي:
                                </h5>

                                <p>
                                    <strong>8.1.1.1</strong> تسجيل حساب ببيانات
                                    صحيحة ودقيقة ومعتمدة، وتقديم وإرفاق البيانات
                                    والمستندات التي تثبت ملكية العقار أو لديه حق
                                    التصرف فيه.
                                </p>

                                <p>
                                    <strong>8.1.1.2</strong> عدم نشر الإعلانات
                                    الكاذبة أو المضللة، ويلتزم بتوضيح كافة
                                    تفاصيل العقار وصور حقيقية وواقعيّة وبجودة
                                    عالية للعقر والمرافق.
                                </p>

                                <p>
                                    <strong>8.1.1.3</strong> الحفاظ على سرية
                                    وخصوصية معلومات العملاء التي حصل عليها أثناء
                                    عملية الحجز.
                                </p>

                                <p>
                                    <strong>8.1.1.4</strong> دفع عمولة تطبيق
                                    نزهة وفقاً للاتفاق المبرم مع شركة
                                    نزهة للخدمات العقارية.
                                </p>
                            </section>

                            <section>
                                <h5>
                                    <strong>8.1.2</strong> يقر موفر الخدمة بأن
                                    تطبيق نزهة ليس وكيلاً عنه ولا
                                    يمثله، وبالتالي يتحمل موفر الخدمة المسئولية
                                    الكاملة عن الحجوزات.
                                </h5>
                            </section>

                            <section>
                                <h5>
                                    <strong>8.1.3</strong> يوافق العميل على
                                    تفويض تطبيق نزهة في تحصيل رسوم
                                    الحجوزات نيابة عنه من العملاء.
                                </h5>
                            </section>

                            <section>
                                <h5>
                                    <strong>8.1.4</strong> في حالة حدوث أيّ خلاف
                                    بين موفر الخدمة والعميل بشأن أيّ حجز فإنه
                                    سيلجأ إلى تنفيذ العقد المبرم بينهما بوسائل
                                    التسوية الودية أو القضائية دون الرجوع على
                                    تطبيق نزهة بأيّ شكل من الأشكال.
                                </h5>
                            </section>

                            <h4><strong>8.2.</strong>العملاء</h4>

                            <section>
                                <h5>
                                    <strong>8.2.1</strong> يتعهد العميل بالآتي:
                                </h5>

                                <p>
                                    <strong>8.2.1.1</strong> الامتثال لشروط
                                    وأحكام التطبيق وكافة القوانين المعمول بها في
                                    سلطنة عمان.
                                </p>

                                <p>
                                    <strong>8.2.1.2</strong> الإقرار بأنّ الحساب
                                    شخصي، وتحمُل المسؤولية الكاملة عن كافة
                                    التصرفات الصادرة منه.
                                </p>

                                <p>
                                    <strong>8.2.1.3</strong> إضافة جميع البيانات
                                    المطلوبة والتحقق من صحتها، بما في ذلك
                                    معلومات الدفع والاتصال وغير ذلك من
                                    المعلومات.
                                </p>

                                <p>
                                    <strong>8.2.1.4</strong> استخدام التطبيق
                                    لأغراض شخصيّة والجدية عند إجراء الحجوزات،
                                    فلا يجوز له إجراء أي حجوزات وهمية.
                                </p>
                            </section>

                            <section>
                                <h5>
                                    <strong>8.2.2</strong> يلتزم العميل بالآتي:
                                </h5>

                                <p>
                                    <strong>8.2.2.1</strong> دفع مبلغ التأمين
                                    عند الحجز ويكون قابل للاسترداد في نهاية
                                    الحجز.
                                </p>

                                <p>
                                    <strong>8.2.2.2</strong> دفع قيمة الحجز
                                    كاملاً بما في ذلك الضرائب بإحدى الوسائل
                                    المتاحة بالتطبيق.
                                </p>

                                <p>
                                    <strong>8.2.2.3</strong> الحفاظ على نظافة
                                    العقار وكافة المرافق والملحقات المتعلقة به.
                                </p>

                                <p>
                                    <strong>8.2.2.4</strong> عدم إتلاف أو التسبب
                                    بإحداث أيّ أضرار أو خسائر للعقار والمرافق
                                    الخاصة به.
                                </p>
                            </section>

                            <section>
                                <h5>
                                    <strong>8.2.3</strong> لا يحق للعميل استرداد
                                    قيمة التأمين إلا بعد التأكد من سلامة العقار
                                    وكافة المرافق الخاصة به، والتأكد من نظافته
                                    وعدم إلحاقه أو مرافقه بأيّ أضرار أو خسائر أو
                                    إتلاف أو أعمال تؤدي إلى تخريب العقار.
                                </h5>
                            </section>

                            <section>
                                <h5>
                                    <strong>8.2.4</strong> يقر العميل بأن تطبيق
                                    نزهة لا يتحمل المسئولية أو يقدم
                                    ضمانات بشأن الحجوزات أو البيانات أو الصور
                                    التي تتعلق بالحجز ويقر بأنّ التطبيق ما هو
                                    إلا وسيط بينه وبين موفر الخدمة.
                                </h5>
                            </section>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>9)</strong> سياسة الإلغاء</h3>
                        <p>
                            <strong>9.1.</strong> باستخدامك الخدمة وإجراء حجز في
                            التطبيق، فأنت تقبل وتوافق على سياسة الإلغاء التي
                            يقدمها موفر الخدمة أسفل العقار المراد حجزه.
                        </p>
                        <p>
                            <strong>9.2.</strong> يمكنك تغيير أو إلغاء الحجز من
                            خلال تسجيل الدخول إلى حسابك بالتطبيق، أو عن طريق
                            الاتصال بخدمة العملاء.
                        </p>
                        <p>
                            <strong>9.3.</strong> قد يفرض موفر الخدمة أو مالك
                            العقار رسوماً أخرى على التغيير و/أو الإلغاء، وتختلف
                            هذه الرسوم حسب سياسة الإلغاء لموفري الخدمات فقد تكون
                            مجانية أو تتطلب رسوم.
                        </p>
                        <p>
                            <strong>9.4.</strong> يرجى العلم بأنه في حالة الحجز
                            في فترة العروض الخاصة قد لا تتمكن من استرداد مبلغ
                            التأمين المدفوع حسب سياسة موفر الخدمة.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>10)</strong> سياسة الدفع</h3>
                        <p>
                            <strong>10.1.</strong> نوفّر لكم الدفع من خلال: فيزا
                            – باي بال – التحويل البنكي.
                        </p>
                        <p>
                            <strong>10.2.</strong> تستحق جميع المعاملات بشكل
                            فوري في التطبيق من خلال وسائل الدفع، ويجب على العميل
                            التأكد من أنّ معلومات الدفع صحيحة ودقيقة وسارية
                            المفعول قبل إتمام عملية الدفع.
                        </p>
                        <p>
                            <strong>10.3.</strong> لا يتم تخزين أرقام البطاقة
                            الائتمانية على أنظمتنا، كما يتم حماية تفاصيل الدفع
                            قبل نقلها باستخدام أحدث تقنيات الحماية الالكترونية
                            التي تتبع أعلى معايير الأمان، حيث نستخدم وسائل تأمين
                            تقنية لعملية الدفع للحد من ظاهرة قرصنة الحسابات
                            الإلكترونية؛ وبالتالي نحتفظ بحقنا في رفض أيّ عملية
                            دفع في أي وقت، إذا رأينا أن تلك العملية قد تتسبب في
                            مشكلات تتعلق باختراق الحسابات البنكية؛ إلا اننا لا
                            نضمن ذلك ولا نتحمل أيّ مسؤولية في حالة حدوث خطأ من
                            المستخدم في عملية الدفع، كما لا نتحمل أيّ مسؤولية
                            نتيجة عمليات القرصنة أو الاحتيال على بطاقاته
                            المستخدمة على الانترنت.
                        </p>
                        <p>
                            <strong>10.4.</strong> جميع المعلومات والبيانات التي
                            تقوم بإدخالها عن طريق وسائل الدفع يتم تشفيرها بغرض
                            الحماية الأمنية، ويتم أيضا تشفير المراسلات المتداولة
                            من وإلى موقع موفر الخدمة.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>11)</strong> تراخيص وقواعد الاستخدام</h3>

                        <section>
                            <h4>
                                <strong>11.1</strong> تمنحك شركة نزهة للخدمات
                                العقارية ترخيصاً محدوداً وغير حصري وغير قابل
                                للترخيص وغير قابل للإلغاء وغير قابل للتحويل أو
                                الترخيص من الباطن من أجل الوصول إلى واستخدام
                                التطبيق والخدمات لإجراء الحجوزات وأيّ محتوى أو
                                معلومات أو مواد ذات صلة من خلال الخدمات فقط
                                لأغراضك الشخصية.
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>11.2</strong> يجوز لك استخدام تطبيق
                                نزهة بالشكل المسموح به قانوناً ووفقاً
                                لبنود هذه الشروط والأحكام فقط؛ وتخضع الحقوق
                                الممنوحة لك في هذه الشروط للقيود التالية؛ لا
                                يجوز لك:
                            </h4>

                            <p>
                                <strong>11.2.1</strong> استخدام التطبيق لأيّ غرض
                                غير قانوني أو غير مشروع أو لانتهاك أيّ قانون أو
                                لوائح أو أنظمة أو قواعد سارية.
                            </p>

                            <p>
                                <strong>11.2.2</strong>ترخيص أو بيع أو تأجير أو
                                نقل أو تخصيص أو توزيع أو استضافة أو استغلال
                                الخدمة.
                            </p>

                            <p>
                                <strong>11.2.3</strong> تعديل أو نسخ أو تكييف أو
                                ترجمة أو استخراج كود المصدر أو إجراء هندسة عكسية
                                لأي جزء من التطبيق، أو عمل أي إصدارات مشتقة من
                                التطبيق أو إعادة تنسيق أو تأطير أي جزء من
                                الصفحات التي تضم التطبيق، باستثناء الحد المسموح
                                به صراحة في هذه الشروط أو بموجب القانون.
                            </p>

                            <p>
                                <strong>11.2.4</strong> الوصول إلى الخدمة أو
                                التطبيق من أجل إنشاء خدمات مشابهة أو منافسة.
                            </p>

                            <p>
                                <strong>11.2.5</strong> إزالة أو إتلاف أيّ
                                شعارات أو علامات الملكية الواردة في التطبيق، أو
                                انتهاك أي علامات تجارية أو حقوق نشر أو حقوق
                                ملكية أخرى لأي طرف.
                            </p>

                            <p>
                                <strong>11.2.6</strong> محاولة الوصول غير المصرح
                                به إلى الخدمة أو أيّ شبكات أو خوادم أو أنظمة
                                متصلة بالتطبيق، وذلك باستخدام أي روبوت أو متتبع
                                ملفات أو وسائل آلية أخرى أو أي عملية يدوية لأي
                                غرض لا يتوافق مع هذه الشروط أو بدون إذن كتابي
                                صريح منا.
                            </p>

                            <p>
                                <strong>11.2.7</strong> التدخل في تشغيل التطبيق
                                أو التحايل على وظائف الأمان في التطبيق أو الوصول
                                إلى أي جزء من التطبيق غير مصرح لكَ بالوصول إليه.
                            </p>

                            <p>
                                <strong>11.2.8</strong> العبث بالبيانات
                                والمعلومات ومحاولة تعديل أو إتلاف أي إجراءات
                                أمنية أو توثيقية.
                            </p>

                            <p>
                                <strong>11.2.9</strong> نشر أي مواد ترويجية غير
                                مصرّح بها، أو نشر الرسائل الإلكترونية غير
                                المرغوب فيها (البريد العشوائي) أو الرسائل التي
                                تدعم أيّ أنشطة غير قانونية.
                            </p>

                            <p>
                                <strong>11.2.10</strong> نشر أو مشاركة أيّ مواد
                                أو معلومات أو عبارات تشهيرية أو مخالفة أو بذيئة
                                أو غير لائقة أو غير مشروعة، أو محتوى فاحش أو
                                إباحي، أو تعليقات عنصرية أو مهينة أو غير لائقة
                                أو تحتوي على ألفاظ نابية أو تُسيء إلى أيّ مستخدم
                                آخر أو تقلل من احترام ثقافة الآخرين.
                            </p>

                            <p>
                                <strong>11.2.11</strong> نشر معلومات أو مواد
                                تنتهك حقوق الملكية الفكرية أو الخصوصية لأي طرف
                                ثالث أو الغير.
                            </p>

                            <p>
                                <strong>11.2.12</strong> نشر أو بث أو إدخال أي
                                مواد تحتوي على فيروسات أو أي برامج أخرى ضارة أو
                                أية شفرات خبيثة أو ملفات أو برامج حاسوبية أخرى
                                مصممة للإضرار أو التدخل في التشغيل العادي للخدمة
                                (أو أي جزء منها)، أو لأي برامج أو أجهزة حاسوبية
                                أخرى.
                            </p>

                            <p>
                                <strong>11.2.13</strong> نقل حسابك واسم المستخدم
                                إلى طرف آخر دون موافقة منا.
                            </p>

                            <p>
                                <strong>11.2.14</strong> جمع أو تتبع أو الكشف عن
                                المعلومات الشخصيّة لأي سبب من الأسباب بما في ذلك
                                على سبيل المثال لا الحصر عناوين البريد
                                الإلكتروني ومعلومات جهات الاتصال.
                            </p>

                            <p>
                                <strong>11.2.15</strong> التسبب في إلحاق الخدمة
                                أو التطبيق أو العلامات التجارية بأيّ أضرار بأي
                                شكل من الأشكال أو التلاعب أو محاولة التلاعب في
                                التطبيق بأي طريقة بما في ذلك الأسعار إما بمفردك
                                أو بالاشتراك مع مستخدمين آخرين.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>11.3</strong> أنت تقر وتوافق على أن عدم
                                التزامك بأيّ مما سبق أو بشروط وسياسات قواعد
                                تشغيل التطبيق قد يؤدي إلى الإنهاء الفوري لحسابك
                                أو أيّ خدمات مقدمة لك، وبالتالي تنتهي كافة
                                التراخيص الممنوحة لك من قِبل شركة نزهة للخدمات
                                العقارية.
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>11.4</strong> جميع الحقوق غير الممنوحة
                                لك صراحة في هذه الشروط تحتفظ بها شركة نزهة
                                للخدمات العقارية والمرخِصّين لها، ويحظر عليك
                                التنازل عنها إلى الغير أو استعمال أيّ حقوق خاصة
                                بنا في أيّ وقت دون الحصول على موافقة صريحة منا
                                بذلك.
                            </h4>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>12)</strong> الدقة والمراقبة</h3>
                        <p>
                            <strong>12.1</strong> لا تقدم شركة نزهة للخدمات
                            العقارية أيّ تعهدات أو ضمانات حول دقة أو موثوقية أو
                            اكتمال أيّ محتوى أو معلومات أو برامج أو نصوص أو
                            رسومات أو روابط أو اتصالات تقدم من خلال التطبيق أو
                            أن التطبيق سيكون خالياً من الأخطاء.
                        </p>
                        <p>
                            <strong>12.2</strong> يتحمل المستخدم مسئولية
                            البيانات والمعلومات التي يدخلها من خلال التطبيق سواء
                            من حيث دقتها أو اكتمالها أو أي أخطاء فيها.
                        </p>
                        <p>
                            <strong>12.3</strong> نحتفظ بالحق في مراقبة الخدمة
                            لتحديد مدى الالتزام بالشروط والأحكام وأيّ قواعد
                            تشغيل تنشر على التطبيق، أو الالتزام بأيّ نظام أو
                            قانون أو لائحة أو أيّ طلب حكومي مصرح به، كما نحتفظ
                            بالحق في إزالة أيّ محتوى نرى أنه ينتهك أحكام هذه
                            الشروط أو التزامات مانحي التراخيص التابعين للتطبيق.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>13)</strong> حقوق الملكية الفكرية</h3>
                        <section>
                            <p>
                                <strong>13.1.</strong> تحتفظ شركة نزهة للخدمات
                                العقارية بجميع الحقوق في التطبيق والخدمة،
                                وبالتالي يحظر نسخ أيّ جزء منها أو تقليدها أو
                                تعديلها أو تشويهها أو القيام بأي عمل ينتهك
                                حقوقنا المالية أو الأدبية على أيّ منها.
                            </p>

                            <p>
                                <strong>13.2.</strong> جميع حقوق الملكية الفكرية
                                في الخدمة والمحتويات المتعلقة بالتطبيق (بما في
                                ذلك على سبيل المثال لا الحصر: النصوص والرسومات
                                والشعارات وأيقونات الأزرار والصور ومقاطع الصوت
                                والفيديو والمواد الرقمية والوثائق ومجموعات
                                البيانات والبرمجيات وحقوق البرمجة والرموز الأخرى
                                التي تحتوي عليها (يشار إليها بشكل جماعي باسم
                                "الملكية الفكرية") تظل ملكاً لـ شركة نزهة
                                للخدمات العقارية وهي محمية بموجب حقوق وقوانين
                                الملكية الفكرية أو حقوق النشر العالمية.
                            </p>

                            <p>
                                <strong>13.3.</strong> لا يجوز لك استخدام أي جزء
                                من المحتوي على التطبيق لأغراض تجارية دون الحصول
                                على ترخيص منا للقيام بذلك أو المرخصين لدينا.
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>14)</strong> العلامات التجارية</h3>

                        <p>
                            <strong>14.1.</strong> الشعارات والكلمات والأسماء
                            التجارية ورؤوس الصفحات وأيقونات الأزرار والأسماء
                            الخدمية المتعلقة به والموجودة على التطبيق (مجتمعة
                            "العلامات التجارية") هي ملك شركة نزهة للخدمات
                            العقارية ويراعى أن أيّ أسماء وعلامات تجارية وعلامات
                            خدمة لأطراف ثالثة تظهر على خدماتنا هي ملك لأصحابها
                            المعنيين.
                        </p>

                        <p>
                            <strong>14.2.</strong> لا يحق لك استخدام أو نسخ أو
                            تقليد أو وضع أيّ من العلامات التجارية أو براءات
                            الاختراع أو الأسرار التجارية أو حقوق التصميم أو
                            المظهر التجاري أو الشعارات بأيّ مكان لأغراض ترويجية
                            بقصد أو عن غير قصد، أو على أيّ محتوى غير مملوك لنا
                            دون الحصول على موافقة رسمية مسبقة منا.
                        </p>

                        <p>
                            <strong>14.3.</strong> يعد أيّ استخدام غير مصرح به
                            أو نسخ أو تقليد أو تشويه لعلامتنا التجارية انتهاكًا
                            لحقوقنا الواردة في قوانين حماية العلامات التجارية؛
                            وبالتالي نحتفظ بكافة حقوقنا في اقتضاء التعويضات
                            المناسبة، واللجوء إلى المحاكم الجنائية؛ لتوقيع
                            عقوبات انتهاك حقوق العلامات التجارية.
                        </p>

                        <p>
                            <strong>14.4.</strong> لا يفسر أيّ شيء وارد في
                            التطبيق على أنه يمنح أيّ ترخيص أو حق في استخدام أيّ
                            علامة تجارية بدون إذن خطي مسبق من الطرف الذي يملك
                            العلامة التجارية.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>15)</strong> إخلاء المسؤولية القانونية</h3>

                        <section>
                            <h4>
                                <strong>15.1.</strong> ما لم يتم التعبير عنه
                                صراحةً في هذه الشروط وإلى الحد المسموح به
                                قانونًا، لا تقدم شركة نزهة للخدمات العقارية أيّ
                                ضمانات أو تعهدات من أيّ نوع سواء كانت صريحة أو
                                ضمنية فيما يتعلق باستخدام التطبيق أو أن الخدمات
                                المتاحة ستلبي توقعاتكم ومتطلباتكم، ويتم توفير
                                التطبيق والمحتوى والمعلومات الواردة فيها على
                                أساس ثابت "كما هو" و "كما هو متاح".
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>15.2.</strong> لن تتحمّل شركة نزهة
                                للخدمات العقارية تحت أيّ ظرف من الظروف المسؤولية
                                عن أيّ أضرار مباشرة أو غير مباشرة أو عرضية أو
                                خاصة أو تبعية أو عقابية أو استثنائية أو أيّ
                                أضرار أخرى أيًا كانت فيما يتعلق بأيّ استخدام
                                للتطبيق بما في ذلك على سبيل المثال لا الحصر:
                            </h4>

                            <p>
                                <strong>15.2.1</strong> أيّ معلومات أو بيانات
                                تتعلق بالحجوزات، او دقة وجودة الصور للعقار.
                            </p>

                            <p>
                                <strong>15.2.2</strong> أيّ مسائل خارجة عن
                                سيطرتنا، بما في ذلك على سبيل المثال لا الحصر عدم
                                صحة أو عدم اكتمال بيانات ومعلومات الإدخال
                                المقدمة على مواقع الأطراف الأخرى التي قد تكون
                                مرتبطة بالتطبيق، بشكل مباشر أو غير مباشر.
                            </p>

                            <p>
                                <strong>15.2.3</strong> أيّ خسارة تنشأ عن أيّ
                                أمور خارجة عن إرادتنا ولا دخل لنا فيها، وهذا
                                يتضمن أعطال المعدات والأجهزة الإلكترونية أو
                                الاتصالات (بما في ذلك جهاز الحاسوب، والهاتف،
                                والإنترنت)، أو الوصول غير المشروع، أو أخطاء
                                التشغيل، أو أيّ أمور أخرى.
                            </p>

                            <p>
                                <strong>15.2.4</strong> التدخل في أيّ تطبيق آخر
                                على جهازك أو إلحاق الضرر به.
                            </p>

                            <p>
                                <strong>15.2.5</strong> تعطيل أو وقف التطبيق عن
                                العمل، أو عدم تحديث التطبيق، أو استخدام التطبيق
                                مع أيّ نظام تشغيل غير متوافق.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>15.3.</strong> لن نتحمّل أيّ مسؤولية عن
                                أيّ روابط أو نصوص منبثقة لمواقع خارجية لا نملكها
                                يُمكن الوصول إليها من خلال التطبيق، أو عن أيّ
                                تعليمات أو محتويات مقدمة عليها، وفي حال قررت
                                الارتباط بأيّ من هذه المواقع الخارجية، فأنت تقوم
                                بذلك بالكامل على مسؤوليتك الشخصيّة.
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>15.4.</strong> أنت تؤكد بأن جميع
                                المعلومات والبيانات التي تُقدمها لنا صحيحة
                                ودقيقة وحديثة، بما في ذلك معلومات الدفع، وتكون
                                على مسؤوليتك الشخصيّة، وتكون مسؤول قانوناً في
                                الحالات التالية:
                            </h4>

                            <p>
                                <strong>15.4.1</strong> إخلالك بأحد بنود شروط
                                وسياسات التطبيق.
                            </p>

                            <p>
                                <strong>15.4.2</strong> استخدام التطبيق لأغراض
                                تتعارض بشكل صريح أو ضمني مع أغراض هذه الشروط، أو
                                بشكل يخالف القوانين المعمول بها في سلطنة عمان.
                            </p>

                            <p>
                                <strong>15.4.3</strong> إدخال معلومات غير صادقة
                                أو غير مطابقة أو غير دقيقة أو معلومات خاصة
                                بالغير أو تنتهك أي حق من حقوق الغير كالحق في
                                الخصوصية الشخصية.
                            </p>

                            <p>
                                <strong>15.4.4</strong>التسبب للتطبيق أو موفري
                                الخدمات التابعين لها أو الموظفين لديها بأيّ
                                مشكلات قانونية، أو الإضرار بهم بأيّ شكل من
                                الأشكال.
                            </p>

                            <p>
                                <strong>15.4.5</strong> الالتزام بأيّ شروط خاصة
                                بالجهات الخارجية عند استخدام التطبيق (على سبيل
                                المثال، يجب عليك التأكد من أن استخدامك للتطبيق
                                لا ينتهك أيّ اتفاقيات مثل خدمات الإنترنت والهاتف
                                المحمول).
                            </p>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>16)</strong> محتوى المستخدم</h3>

                        <section>
                            <h4>
                                <strong>16.1.</strong> نرحب بالآراء والردود
                                والتعليقات حول التطبيق والخدمات التي نقدمها بشرط
                                ألا تحتوي على مشاركات أو تعليقات بلغة بذيئة أو
                                تتضمن مواد أو بيانات مزيفة؛ وتوافق على عدم إرسال
                                أو محاولة إرسال أيّ محتوى غير مصرح به أو ينتهك
                                أياً من الشروط والأحكام، بما في ذلك، على سبيل
                                المثال لا الحصر:
                            </h4>

                            <p>
                                <strong>16.1.1</strong> انتهاك حقوق الملكية
                                الفكرية أو حقوق الطبع والنشر أو لأيّ طرف آخر.
                            </p>

                            <p>
                                <strong>16.1.2</strong> التسبب في ضرر أو مضايقة
                                لأيّ شخص أو قد يمنع أو يقيد أو يعيق الآخرين من
                                استخدام التطبيق.
                            </p>

                            <p>
                                <strong>16.1.3</strong> الوصول غير المصرح به إلى
                                معلومات المستخدمين الآخرين.
                            </p>

                            <p>
                                <strong>16.1.4</strong> التعديل أو الإضرار أو
                                التعطيل أو التغيير أو التداخل مع استخدام أو
                                ميزات أو وظائف أو تشغيل أو صيانة ميزات تقديم
                                محتوى للمستخدم.
                            </p>

                            <p>
                                <strong>16.1.5</strong> أيّ متطلبات أو قواعد أو
                                شروط أو أحكام قد تنطبق على ميزات تقديم محتوى
                                المستخدم.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>16.2.</strong> نحتفظ في جميع الأوقات
                                بالحق في حذف أو حظر أو إزالة المحتوى الذي نراه
                                وفقاً لتقديرنا الخاص ينتهك هذه الشروط والأحكام.
                            </h4>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>17)</strong> الفيروسات</h3>
                        <p>
                            <strong>17.1</strong> نسعى لبذل قصارى جهدنا لضمان
                            توافر التطبيق للاستخدام، ولكن لا نتحمّل المسؤولية عن
                            أيّ انقطاع محتمل في الخدمة بسبب -على سبيل المثال لا
                            الحصر-أعمال الصيانة أو المشكلات الفنية أو أسباب أخرى
                            مشابهه.
                        </p>
                        <p>
                            <strong>17.2</strong> يجب عليك عدم إساءة استخدام
                            التطبيق من خلال تعمد جلب الفيروسات أو غيرها من
                            المواد التي تعتبر ضارة تقنياً، وألا تحاول الحصول على
                            دخول غير مصرح به إلى التطبيق أو أيّ خادم أو قاعدة
                            بيانات متصلة بالتطبيق.
                        </p>
                        <p>
                            <strong>17.3</strong> لن نتحمل المسؤولية عن أيّ
                            خسائر أو أضرار ناتجة عن الفيروسات أو غيرها من المواد
                            الضارة تقنياً والتي قد تصيب أجهزتك أو برامج
                            الكمبيوتر أو البيانات أو غيرها من المواد المملوكة لك
                            بسبب استخدامك للتطبيق أو لتنزيل أيّ محتوى عليه أو
                            على أيّ موقع يرتبط به.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3>
                            <strong>18)</strong> الاتصالات الإلكترونية
                            والإشعارات
                        </h3>
                        <p>
                            <strong>18.1</strong> عند استخدام تطبيق
                            نزهة أو إرسال بريد إلكتروني لنا، أو التواصل
                            معنا من خلال الوسائل المتاحة بالتطبيق، فأنت بذلك
                            تتواصل معنا إلكترونياً وتوافق صراحةً على استلام
                            إشعارات ورسائل إلكترونية منا لأغراض تنفيذ الخدمات
                            على سبيل المثال لا الحصر التذكير بالحجوزات، أو
                            الإخطارات التي يجب إخطارك بها بموجب القانون، أو التي
                            تتعلق باستحداث بعض الخصائص الجديدة بالتطبيق أو
                            العروض الخاصة والخصومات، كما توافق على أن جميع
                            الإشعارات والإفصاحات وغيرها من الاتصالات التي نقدمها
                            لك إلكترونياً تستوفي كافة الشروط القانونية كما لو
                            كانت هذه الاتصالات خطية؛ وتقوم مقامها في إنتاج
                            آثارها القانونية.
                        </p>
                        <p>
                            <strong>18.2</strong> إذا كنت لا ترغب في استلام مثل
                            هذه الرسائل يمكنك إلغاء الاشتراك في هذه الرسائل في
                            أيّ وقت من خلال النقر على رابط إلغاء الاشتراك الذي
                            يظهر أسفل كل رسالة، أو عن طريق الاتصال بنا على
                            الوسائل المتاحة.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>19)</strong> روابط الأطراف الأخرى</h3>
                        <p>
                            قد تحتوي الخدمات على روابط تشعبية ووظائف تفاعلية
                            لمواقع إلكترونية تشغلها جهات خارجية لا تملكها شركة
                            نزهة للخدمات العقارية ولا تتحكم فيها ولا تديرها ولا
                            تخضع لسيطرتها، ويعتبر تطبيق نزهة مجرد
                            مزود معلومات وليس خدمة إحالة، ولا يوصي ولا يؤيد ولا
                            يراقب ولا يتحكم في أي روابط أو مواقع أو خدمات لهذ
                            الجهات الخارجية؛ لذلك لا نقدم أيّ ضمانات أو تعهدات
                            من أيّ نوع فيما يتعلق بهذه الجهات الخارجية أو
                            المحتوى الذي تقدمه أو سياسة الخصوصية أو الممارسات
                            الأخرى لها.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>20)</strong> التعويض</h3>
                        <h4>
                            باستخدام خدماتنا وموافقتك على هذه الشروط والسياسات،
                            فأنت توافق على تعويض شركة نزهة للخدمات العقارية
                            وحمايتها (بموجب القضاء) وتابعيها وشركائها وموظفيها
                            ومدراءها ووكلائها من أي وجميع المطالبات والمسؤوليات
                            والالتزامات والأضرار والخسائر والمصروفات والنفقات
                            الأخرى أيًا كان نوعها أو طبيعتها (بما في ذلك الرسوم
                            القانونية وأتعاب المحاماة)، التي تنشأ عن أو تتعلق
                            بـ:
                        </h4>
                        <p>
                            <strong>20.1</strong> إساءة استخدام التطبيق، أو أيّ
                            استخدام غير قانوني للتطبيق مهما كان نوعه.
                        </p>
                        <p>
                            <strong>20.2</strong> انتهاك أيّ من الشروط والسياسات
                            أو القواعد أو الإرشادات المتبعة لدينا.
                        </p>
                        <p>
                            <strong>20.3</strong> انتهاك أيّ نظام أو قانون ساري
                            أو حقوق الملكية الفكرية.
                        </p>
                        <p>
                            <strong>20.4</strong> أيّ تزييف في أيّ معلومات أو
                            وسائل تواصل أو انتحال شخصية الغير.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>21)</strong> التعديلات</h3>

                        <section>
                            <h4>
                                <strong>21.1</strong> نحتفظ من وقت لآخر بالحق في
                                تغيير أو تعديل أو استكمال أو إزالة أجزاء من هذه
                                الشروط، وستصبح هذه الشروط سارية عند نشرها على
                                التطبيق، وتوافق على أن استمرارك في استخدام
                                التطبيق يعد موافقة صريحة على الشروط المعدّلة،
                                وتوافق على أنّ النسخة المعدّلة حلت محل جميع
                                النسخ السابقة من هذه الشروط.
                            </h4>
                        </section>

                        <section>
                            <h4>
                                <strong>21.2</strong> نحتفظ بالحق في إيقاف أو
                                تعديل أو تغيير أي جانب من جوانب الخدمات المقدمة
                                أو أيّ ميزة أو وظيفة لها في أيّ وقت دون إخطارك،
                                وستظل جميع الحقوق والملكية والمصالح في الخدمة
                                ومكوناتها (بما في ذلك جميع حقوق الملكية الفكرية)
                                مملوكة لـ شركة نزهة للخدمات العقارية وتنتمي
                                إليها حصرياً، بما في ذلك على سبيل المثال لا
                                الحصر:
                            </h4>

                            <p>
                                <strong>21.2.1</strong> تقييد الوقت المتاح
                                للخدمات.
                            </p>

                            <p>
                                <strong>21.2.2</strong> تقييد مقدار الاستخدام
                                المسموح به.
                            </p>

                            <p>
                                <strong>21.2.3</strong> تقييد أو إنهاء حق أي
                                مستخدم في استخدام الخدمات.
                            </p>

                            <p>
                                <strong>21.2.4</strong> تغيير أي ميزة أو وظيفة
                                توفرها الخدمات.
                            </p>
                        </section>

                        <section>
                            <h4>
                                <strong>21.3</strong> أنت المسؤول عن مراجعة هذه
                                الشروط بانتظام حتى تكون على علم ودراية بأيّ
                                تغييرات فيها وستكون ملزماً بالسياسة الجديدة عند
                                استمرارك في استخدام التطبيق، وفي حالة عدم قبول
                                الشروط المعدّلة، يجب عليك التوقف عن استخدام
                                التطبيق والخدمة.
                            </h4>
                        </section>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>22)</strong> الإنهاء</h3>
                        <p>
                            <strong>22.1</strong> تظل هذه الشروط سارية المفعول
                            أثناء وصولك إلى التطبيق ما لم يتم إنهائها من قبلنا
                            أو من قبلك، ويحق لنا وفقًا لتقديرنا الخاص بتعليق أو
                            تقييد وصولك إلى التطبيق بإشعار أو بدون إشعار، وفي
                            حال قررنا ذلك لن نتحمل أيّ مسؤولية أو التزامات تجاهك
                            أو أيّ مستخدم آخر بأيّ شكل من الأشكال، كما نحتفظ
                            بالحق في إلغاء أي حجوزات أو مدفوعات لأي سبب كان قبل
                            معالجة الدفع إذا كانت تنطوي على مخالفة لهذه الشروط.
                        </p>
                        <p>
                            <strong>22.2</strong> يمكنك إنهاء أحكام هذه الشروط
                            في أيّ وقت عن طريق التوقف عن استخدام الخدمات أو
                            إلغاء تثبيت التطبيق، وعند الإنهاء ستتوقف كافة الحقوق
                            و/أو التراخيص الممنوحة لك بموجب هذه الشروط ما لم يتم
                            إخطارك بخلاف ذلك، وتوافق على التوقف عن استخدام
                            التطبيق.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3>
                            <strong>23)</strong> القوانين المطبقّة والاختصاص
                            القضائي
                        </h3>
                        <p>
                            <strong>23.1</strong> تخضع هذه الشروط والسياسات
                            الأخرى والتعديلات اللاحقة عليها وأيّ خلافات تتعلق
                            بالحجوزات أو الخدمات للقوانين السارية في سلطنة عمان،
                            وينعقد الاختصاص القضائي للمحاكم العمانية.
                        </p>
                        <p>
                            <strong>23.2</strong> إذا اعتبر أيّ من هذه الشروط
                            غير قانوني أو باطل أو غير قابل للتنفيذ بموجب
                            القوانين السارية في سلطنة عمان أمام أيّ اختصاص
                            قضائي، فسيعتبر هذا البند مفصولاً عن هذه الشروط، ولا
                            يؤثر على قانونية وصحة وصلاحية وقابلية باقي البنود.
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>24)</strong> أحكام عامة</h3>
                        <p>
                            <strong>24.1</strong> الخصوصية: أثناء استخدامك
                            للتطبيق والخدمات سنجمع منك بعض المعلومات الشخصيّة
                            وسيتم التعامل معها وفقًا لسياسة الخصوصية لدينا، يرجى
                            الاطلاع عليها لمعرفة الممارسات والإجراءات التي نقوم
                            بها.
                        </p>
                        <p>
                            <strong>24.2</strong> حظر التنازل: لا يحق لك التنازل
                            عن التزاماتك أو حقوقك أو مسؤولياتك الواردة بهذه
                            الشروط، أو أن تعهد بإدارة حسابك إلى طرف آخر إلا بعد
                            الحصول على موافقة خطية منا.
                        </p>
                        <p>
                            <strong>24.3</strong> العلاقة: لا يفسر استخدامك
                            للتطبيق والخدمة على وجود أيّ علاقة شراكة أو توظيف أو
                            وكالة بين شركة نزهة للخدمات العقارية وبينك كمستخدم،
                            ولا يحق لك إلزامنا بأيّ شيء وبأيّ شكل من الأشكال.
                        </p>
                        <p>
                            <strong>24.4</strong> القوة القاهرة: نحن لا نتحمل
                            أيّ مسئولية عن الإخفاق أو التأخر في الأداء متى كان
                            ذلك ناجماً عن ظروف خارجة عن إرادتنا أو سيطرتنا أو
                            بسبب القوة القاهرة. كما لا نتحمل المسئولية عند وقوع
                            أيّ حادث طارئ مما يؤدي إلى القيام بتعليق أو تعطيل
                            عمل التطبيق بصورة تلقائية.
                        </p>
                        <p>
                            <strong>24.5</strong> اللغة: تم صياغة هذه الشروط
                            والسياسات الأخرى باللغة العربية، وفي حال ترجمتها إلى
                            أيّ لغة أجنبية أخرى، فإنّ اللغة العربية هي المعتمدة
                            والسارية أمام جميع الهيئات الرسمية وغير الرسمية، وفي
                            حال تعارضت هذه الشروط مع أيّ من الاصدارات السابقة
                            لها، فإنّ النسخة الحالية هي السائدة ما لم ينص على
                            خلاف ذلك.
                        </p>
                        <p>
                            <strong>24.6</strong> الاتفاق الكامل: تشكل هذه
                            الشروط وأيّ سياسات أو قواعد تشغيل منشورة من قبلنا
                            على التطبيق أو فيما يتعلق بالخدمة الاتفاق الكامل
                            والنهائي بين شركة نزهة للخدمات العقارية من ناحية،
                            وأيّ شخص يزور أو يستخدم التطبيق من ناحية أخرى، وتحل
                            محل أيّ اتفاقيات أو اتصالات سابقة أو حالية (على سبيل
                            المثال، أيّ إصدارات سابقة من هذه الشروط والسياسات).
                        </p>
                    </div>

                    <div class="info-column col-12">
                        <h3><strong>25)</strong> معلومات الاتصال</h3>
                        <p>
                            إذا كان لديك أيّ أسئلة حول هذه الشروط أو تتعلق
                            باستخدام التطبيق، لا تتردد في الاتصال بنا من خلال
                            الوسائل المتاحة بالتطبيق.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <!-- About Us -->
    </div>
</template>

<script>
export default {
    name: "TermsAndConditions",
};
</script>

<style scoped>
.privacy-policy p,
.privacy-policy h4,
.privacy-policy h3 {
    margin: 30px 0;
}
</style>
